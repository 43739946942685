import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

 

 
if (!environment.production) {
var script = document.createElement('script');
script.src = 'https://www.paypal.com/sdk/js?client-id='+environment.paypalApiKey+'&currency=USD'
script["data-namespace"]="paypal_sdk";
 console.log('script',script)
document.head.appendChild(script);
}

if (environment.production) {
  enableProdMode();
  const script = document.createElement('script');
  script.src = 'https://www.paypal.com/sdk/js?client-id=AVJ2RVVsZ89R_QFFWoXwAv_snbbepH9wjDjQA9YlJ73oXkVE3aL_O5pDLMQSSqe3E4TycfU48c7kVJfy&currency=USD';
  document.head.appendChild(script);
}  
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
