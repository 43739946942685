
import { Inject, Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root'
})
export class PaypalService {

   constructor(   ) {
  
  }
  
}
