import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { ApplicationComponent } from './pages/application/application.component';
import { HomeComponent } from './pages/home/home.component';
import { 
  AuthGuardService as AuthGuard, AuthGuardService 
} from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'application',
    loadChildren: () => import('./pages/application/application.module').then(m => m.ApplicationModule),

  },
  {
    path: 'forms',
    loadChildren: () => import('./pages/application/application.module').then(m => m.ApplicationModule),

  },
  
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  }, 
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '',   redirectTo: '/forms', pathMatch: 'full' }, // redirect to `first-component`

      {
        path: 'home', 
       loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full',
        canActivate: [AuthGuardService] 
      },
      // {
      //   path: 'login',
      //   loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
      //   redirectTo: '/login',
      //   pathMatch: 'full'
      // }, 

      {
        path: 'applicants', 
        loadChildren: () => import('./pages/applicant/applicant.module').then(m => m.ApplicantModule),
        canActivate: [AuthGuardService] 
      }, 
      // {
      //   path: 'families', 
      //   redirectTo: '/applicants',
      // }, 
      {
        path: 'families', 
        loadChildren: () => import('./pages/family/family.module').then(m => m.FamilyModule),
        canActivate: [AuthGuardService] 
      },
      {
        path: 'children', 
        loadChildren: () => import('./pages/children/children.module').then(m => m.ChildrenModule),
        canActivate: [AuthGuardService] 
      },
      {
        path: 'classes', 
        loadChildren: () => import('./pages/classes/classes.module').then(m => m.ClassesModule),
        canActivate: [AuthGuardService] 
      },
      {
        path: 'staff', 
        loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule),
        canActivate: [AuthGuardService] 
      },
      {
        path: 'profile', 
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuardService] 
      },
       {
        path: 'apps/inbox',
        loadChildren: () => import('./pages/apps/inbox/inbox.module').then(m => m.InboxModule),
      },
      {
        path: 'apps/calendar',
        loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarAppModule),
      },
      {
        path: 'apps/chat',
        loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
      },
      {
        path: 'components',
        loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule),
      },
      {
        path: 'forms/form-elements',
        loadChildren: () => import('./pages/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
      },
      {
        path: 'forms/form-wizard',
        loadChildren: () => import('./pages/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
      },
      {
        path: 'icons',
        loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule),
      },
      {
        path: 'page-layouts',
        loadChildren: () => import('./pages/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
      },
      {
        path: 'maps/google-maps',
        loadChildren: () => import('./pages/maps/google-maps/google-maps.module').then(m => m.GoogleMapsModule),
      },
      {
        path: 'tables/all-in-one-table',
        loadChildren: () => import('./pages/tables/all-in-one-table/all-in-one-table.module').then(m => m.AllInOneTableModule),
      },
      {
        path: 'drag-and-drop',
        loadChildren: () => import('./pages/drag-and-drop/drag-and-drop.module').then(m => m.DragAndDropModule)
      },
      {
        path: 'editor',
        loadChildren: () => import('./pages/editor/editor.module').then(m => m.EditorModule),
      },
      {
        path: 'blank',
        loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankModule),
      },
      {
        path: 'level1/level2/level3/level4/level5',
        loadChildren: () => import('./pages/level5/level5.module').then(m => m.Level5Module),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
