import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CellComp } from 'ag-grid-community';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Command } from '../models/command';
import { AuthenticationService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  public authenticated = false;
  private apiServer = environment.backend;
  private emailKey = environment.emailKey;
  public sessionId = 'test-session';
  constructor(private _http: HttpClient,
    public auth: AuthenticationService,
    private router: Router) {

  }


  convertJStoSQLDate(dt) {
    if (dt) {
      console.log('date in ' + dt)
      var isdate = (dt instanceof Date && !isNaN(dt.valueOf()));
      if (!isdate) {
        dt = new Date(dt);
      }

      let day = dt.getDate() > 9 ? `${dt.getDate()}` : `0${dt.getDate()}`;
      let month = dt.getMonth() + 1 > 9 ? `${dt.getMonth() + 1}` : `0${dt.getMonth() + 1}`;
      let year = dt.getFullYear();
      // let myDate = `${year}-${month}-${day}T00:00:00`
      let myDate = `${month}/${day}/${year}`
      console.log('date out ' + myDate)
      return myDate;
    }
  }

  sendReceipt(id, obj) {
    console.log('Applicant Receipt', obj)
    let url = this.apiServer + '/api/receipt/send?id=' + id + '&key=' + this.emailKey;

    var emails = [];

    if (obj !== null && typeof obj !== "undefined" && obj.hasOwnProperty('motherEmail')) {
      if (`${obj.motherEmail}`.indexOf('@') > 0) {
        emails.push(obj.motherEmail)
      }
    };
    if (obj !== null && typeof obj !== "undefined" && obj.hasOwnProperty('fatherEmail')) {

      if (`${obj.fatherEmail}`.indexOf('@') > 0) {
        emails.push(obj.fatherEmail)
      }
    }

    let toEmail = emails[0]
    let ccEmail = emails.length>1 ? emails[1]: [];

    
    let postObject = {
      "toEmail": toEmail,
      "ccEmail": ccEmail,
      "displayName": "SLS Preschool Admissions",
      "fromEmail": "admissions@slssanfrancisco.org",
      "subject": "Your application has been successfully submitted"
    }

    return this._http.post(url, postObject)

  }


  postApplicantPayment(id, paymentConfirmation) {

    let cmd = new Command();
    cmd.procedure = 'cmdApply';
    cmd.addParameter('applicantID', id);
    cmd.addParameter('PaymentConfirmation', JSON.stringify(paymentConfirmation));
    return this.command(cmd);
  }

  postApplication(obj) {
    obj.birthDate = this.convertJStoSQLDate(obj.birthDate);
    obj.startDate = this.convertJStoSQLDate(obj.startDate);
    console.log(obj);





    let cmd = new Command();
    cmd.procedure = 'cmdApply';

    cmd.addParameter('ChildFullName', obj.childFullName);
    cmd.addParameter('Gender', obj.gender);
    cmd.addParameter('BirthDate', obj.birthDate);
    cmd.addParameter('NickName', obj.nickName);
    cmd.addParameter('StartDate', obj.startDate);
    cmd.addParameter('Address1', obj.address1);
    cmd.addParameter('City', obj.city);
    cmd.addParameter('State', obj.state);
    cmd.addParameter('ZipCode', obj.zipCode);
    cmd.addParameter('Parent1Relationship', obj.parent1Relationship)
    cmd.addParameter('Parent2Relationship', obj.parent2Relationship)

    cmd.addParameter('MotherFirst', obj.motherFirst);
    cmd.addParameter('MotherLast', obj.motherLast);
    cmd.addParameter('MotherWorkPlace', obj.motherWorkPlace);
    cmd.addParameter('MotherOccupation', obj.motherOccupation);
    cmd.addParameter('MotherEmail', obj.motherEmail);
    cmd.addParameter('MotherPhone', obj.motherPhone);
    cmd.addParameter('FatherFirst', obj.fatherFirst);
    cmd.addParameter('FatherLast', obj.fatherLast);
    cmd.addParameter('FatherWorkPlace', obj.fatherWorkPlace);
    cmd.addParameter('FatherOccupation', obj.fatherOccupation);
    cmd.addParameter('FatherEmail', obj.fatherEmail);
    cmd.addParameter('FatherPhone', obj.fatherPhone);
    cmd.addParameter('NumOfChildren', obj.numOfChildren);
    cmd.addParameter('PreviousExperience', obj.previousExperience);
    cmd.addParameter('ReferredBy', obj.referredBy);
    cmd.addParameter('OutsideResources', obj.outsideResources);
    cmd.addParameter('PaymentConfirmation', JSON.stringify(obj.paymentConfirmation));
    return this.command(cmd);
  }

  checkLogin() {
    console.log('this.auth.sessionId', this.auth.sessionId);
    console.log('this.auth.authenticated', this.auth.isLoggedInLocal)
    if (!this.auth.sessionId || !this.auth.isLoggedInLocal) {
      let url = "/" + window.location.pathname + window.location.search;
      localStorage.setItem('lastPage', url);

      this.router.navigateByUrl('/login');
    }
  }
  addParent(): Promise<any> {

    var url = `${this.apiServer}/api/parent/new?sid=${this.sessionId}`;
    return this._http.post(url, {}).toPromise()

  }
  getFamilies(id?: number): Observable<any> {
    this.checkLogin();
    let cmd = new Command();
    cmd.procedure = 'cmdGetFamilies';
    if (!id) {
      cmd.addParameter('FamilyID', '0');
    } else {
      cmd.addParameter('FamilyID', id);
    }
    return this.command(cmd);
  }

  getFamilyChildren(familyID:number):Observable<any>{
    let cmd= new Command();
    cmd.procedure = 'cmdGetFamilyChildren';
    cmd.addParameter('FamilyID',familyID);
    return this.command(cmd);

  }
  getApplicantByReferenceCode(referenceCode: string) {
    let url = `${this.apiServer}/api/application/${referenceCode}`;
    return this._http.get(url);

  }
  getApplicants(id?: number): Observable<any> {
    this.checkLogin();
    let cmd = new Command();
    cmd.procedure = 'cmdGetApplicants';
    if (!id) {
      cmd.addParameter('ApplicantID', '0');
    } else {
      cmd.addParameter('ApplicantID', id);
    }
    return this.command(cmd);
  }


  getFamilyAddresses(familyID: number): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdGetAddresses';

    cmd.addParameter('FamilyID', familyID);

    return this.command(cmd);
  }
  getClassList(id?: number): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdGetClasses';
    if (!id) {
      cmd.addParameter('ClassID', '0');
    } else {
      cmd.addParameter('ClassID', id);
    }
    return this.command(cmd);
  }


  getStaff(id?: number): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdGetStaff';
    if (!id) {
      cmd.addParameter('StaffID', '0');
    } else {
      cmd.addParameter('StaffID', id);
    }
    return this.command(cmd);
  }

  login(email: string, token: string) {
    let cmd = new Command();
    cmd.procedure = 'cmdLogin';
    cmd.addParameter('Email', email);
    cmd.addParameter('Token', token);
    return this.command(cmd);
  }
  getChildren(id?: number): Observable<any> {
    let cmd = new Command();
    cmd.procedure = 'cmdGetChildren';
    if (!id) {
      cmd.addParameter('ChildID', '0');
    } else {
      cmd.addParameter('ChildID', id);
    }
    return this.command(cmd);
  }

  command(commandObject: any): Observable<any> {


    let endpoint = 'cmd';
    if (commandObject.procedure === 'cmdFieldUpdateApplicant' || commandObject.procedure === 'cmdLogin') {
      endpoint = 'commandspecific';
    }

    let url: string = this.apiServer + '/api/' + endpoint + '?tracker=' + commandObject.procedure + '&sid=' + this.sessionId;
    return this._http.post(url, commandObject)
      .pipe(map((res: any) => res));
    //  .do(x => console.log(x));
  }


}
