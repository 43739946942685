<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false" [routerLink]="['/']" 
    class="toolbar-logo" fxShow>
    <img src="assets/img/logo_dark.png" width="200px">

    </div>
    <div *ngIf="showLogin" class="toolbar-logo" > <img src="assets/img/logo_dark.png" width="200px"></div>
    <div *ngIf="!showLogin" >
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" style="float:right" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
    <!-- <fury-toolbar-search-bar fxFlex="500px" fxHide fxShow.gt-md></fury-toolbar-search-bar> -->
  </div>

  <div fxLayout="row">

    <fury-toolbar-fullscreen-toggle *ngIf="!showLogin" class="toolbar-button"></fury-toolbar-fullscreen-toggle>

    <!-- <fury-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></fury-toolbar-search> -->

    <!-- <fury-toolbar-notifications class="toolbar-button"></fury-toolbar-notifications> -->
    <button mat-raised-button *ngIf="showLogin" (click)="auth.login()"  
    style="margin:10px;color:blue;margin-right:10px">Click Here to Login</button>
    <fury-toolbar-user *ngIf="!showLogin" class="toolbar-button"></fury-toolbar-user>

    <!-- <fury-toolbar-quickpanel-toggle (openQuickPanel)="openQuickPanel.emit()"
                                    class="toolbar-button"></fury-toolbar-quickpanel-toggle> -->
  </div>
</div>

