<div class="sidenav" fxLayout="column" >
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center" style="background-color: white;">
    <div class="sidenav-toolbar-icon" *ngIf="collapsed" >
      <!-- <svg viewBox="0 0 33.74 33.99" xmlns="http://www.w3.org/2000/svg"><title>icon</title>
        <path d="M26.83,19.33c-.36.37-.73.71-1.09,1l.31,3.22a5.41,5.41,0,0,1-2.43,5.14L15.72,34l-.67-8.74a6.54,6.54,0,0,1-6.57-6.53L0,18.18l5-7.93a5.41,5.41,0,0,1,5.23-2.54l3.1.31q.49-.55,1.05-1.11C20.78.54,30.82-.62,33.48.26,34.37,2.93,33.2,13,26.83,19.33Zm-.45-12a3.77,3.77,0,1,0,0,5.33A3.77,3.77,0,0,0,26.38,7.36ZM2.82,23.72l3.24-3.24a8.24,8.24,0,0,0,7.11,7.1L10.5,30.25,7.74,33V29.46L2.41,31.23l1.78-5.32H.64Z"></path>
      </svg> -->
      <img src="http://preschool.morrisdev.com/img/logo3-leaf.png" width="30px">
    </div>
    
    <div [routerLink]="['/']" class="sidenav-toolbar-logo" *ngIf="!collapsed" 
      style="background-color: white;margin-left:-10px">
      <img src="assets/img/logo_dark.png" width="200px">
    </div>

    
    <span fxFlex>
      <!-- fill space -->
    </span>

    <div fxFlexAlign="center" fxHide fxShow.gt-md>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user" >
      <img class="sidenav-user-image" src="assets/img/avatars/default.jpg">
      <h4 class="sidenav-user-name">David Smith</h4>
      <h5 class="sidenav-user-email">david.smith@example.com</h5>

      <div class="sidenav-user-icons" fxLayout="row">
        <a [routerLink]="['/tables/all-in-one-table']" mat-icon-button>
          <mat-icon>assignment</mat-icon>
        </a>
        <a [routerLink]="['/apps/calendar']" mat-icon-button>
          <mat-icon>date_range</mat-icon>
        </a>
        <a [routerLink]="['/login']" mat-icon-button>
          <mat-icon>exit_to_app</mat-icon>
        </a>
      </div>
    </div>

    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>

</div>