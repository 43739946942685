import { Injectable, Inject, Optional } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { AuthenticationService } from "./auth.service";
 @Injectable()
export class UniversalAppInterceptor implements HttpInterceptor {

  constructor( private authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    
   
    let token = localStorage.getItem('token');
   // token = `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImozRUdzVWRsblhRZjNsLXZlWm43RCJ9.eyJpc3MiOiJodHRwczovL3ByZXNjaG9vbGRiLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJCWmRNOVZjSEJ0dElobHhwc29TZldTSGp2aTNqTFZsQUBjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9wcmVzY2hvb2xkYi5jb20vYXBpL2lkZW50aWZpZXIiLCJpYXQiOjE2NjQ2NDAzNTUsImV4cCI6MTY2NDcyNjc1NSwiYXpwIjoiQlpkTTlWY0hCdHRJaGx4cHNvU2ZXU0hqdmkzakxWbEEiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.EBCs9mUFnhrG0YW3FEfzo-_excDoeVx5WboGjklP-goV7bQSvWO2C9KXxKhBr9RyflNM3CIzkJfvhUVGC8baJxUynsbcmRTxN2qH1RvXheGfogYn0gz00648I1fxJAYNDIcpVpgydlASgJQGqt5fjvB2151sH8Bdfs1Smmcm42JHFEbx6ruEtoavcZ8Hh3zBcdrJrdeBmGUfPY50nu713ViJQO8A3Cf4zEo0Hlqdd4K-t1aQwSTSiBUsGWLPqfjOHfg8HGsM7Q2CSYBWCDAeAVvO6A0wtbbdQQ9mz2CzjQl8dXIGRRngoHhbcIoz58inc79NSNe96WVrKnIDB-UWdQ`;
    if(token){
      req = req.clone({
        url:  req.url,
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(req);
  }
}