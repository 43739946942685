import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/auth.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();
  collapsed   
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  showLogin=true;
  constructor(private themeService: ThemeService, public auth:AuthenticationService, private sidenavService:SidenavService) {
    this.auth.user$.subscribe(retval=>{
   //   console.log('user',retval)
      if(retval){
        this.showLogin=false;
      }
    })
  }

  ngOnInit() { //this.auth.isLoggedIn$
  }

  toggleCollapsed() {
    this.collapsed=!this.collapsed
    this.sidenavService.toggleCollapsed();
  }

}
