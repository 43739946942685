export const environment = {
  production: false,
  googleMapsApiKey: 'AIzaSyD7gtowPV_w4QT11oiVAcet29mWzSrSsgU',
  agGridKey:'_Morris_Development_multi_1_Devs__18_December_2020_[v2]_MTYwODI0OTYwMDAwMA==40bd4ad5dc7ba5349fde250c7efa419b',
  //backend: 'https://localhost:5001'  ,
  backend: 'https://preschooldb2.azurewebsites.net',
  logoutReturn:'https://preschooldb.com/login',
  loginReturn: 'https://preschooldb.com/home',
  webserver: 'https://preschooldb.com',
  paypalApiKey: 'AVJ2RVVsZ89R_QFFWoXwAv_snbbepH9wjDjQA9YlJ73oXkVE3aL_O5pDLMQSSqe3E4TycfU48c7kVJfy',
  paypalSecret: 'EJEXoWXIZaxZ-A171oPT4YWfibfo0eTwQbjSfXG8bAtZltBUxIvzBlEDjsJv6xmVeucx8xzmot2PDfcl',
  emailKey:'6ggUCTeHzZVXeN2Ea657bWFqA0lNiceqkoqaQyztdXojmfolk6',
  pdfKey:'onvGvV55oHrTE6cRAGRffBxMTFPbe2jJSKLyFMA2YP8Rd2pM42HgKekQbWQ2iDfLMawfnxRZndm7j2rQ63qd24P8spJcqMR7bkC9'
};

