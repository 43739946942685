import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from './auth.service';
 @Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(): boolean {
    let authorized = this.auth.isLoggedInLocal;
    if (!authorized) {
      console.log("AuthGuardService: FAILED AUTHORIZATION")
      localStorage.setItem('sessionId','');
      localStorage.setItem('expireTime','1/1/1900');
      let url = window.location.pathname + window.location.search;
      localStorage.setItem('lastPage', url);
      this.router.navigate(['login']);
      return false;
    }
    
    return true;
  }
}