import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';

import { AuthModule } from '@auth0/auth0-angular';

import { FormsModule } from '@angular/forms';
import { DataLayerService } from './services/db.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { PaypalService } from './services/paypal.service';
import { UniversalAppInterceptor } from './services/auth-interceptor.service';
   
@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
//Auth0 Authenticator
AuthModule.forRoot({
  domain: 'preschooldb.us.auth0.com',
  clientId: 'kcNyDJzKLuha45FYI75J8blvfJsj74EC',
  redirect_uri: environment.loginReturn,
}),

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    FormsModule, // <-- import the FormsModule before binding with [(ngModel)]

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [FormsModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [AuthGuardService,AuthenticationService,JwtHelperService,
    DataLayerService,AuthService,PaypalService,
    { provide: HTTP_INTERCEPTORS, useClass: UniversalAppInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    }
  ]
})
export class AppModule {
}
