import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from 'src/app/services/auth.service';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isOpen: boolean;

  picture;
  userName;
  constructor(private router: Router, public auth: AuthenticationService) {
    auth.user$.subscribe(user=>{
      if(user) {
        this.picture = user.picture;
        this.userName = user.nickname;
      }
    })
   }

  ngOnInit() {
   


  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  goto(target: string) {
    let url = '/'+target;
    console.log('Navigate to:'+ url)
    this.router.navigateByUrl(url);
    this.isOpen = false;
  }

}
